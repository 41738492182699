import { Injectable } from '@angular/core';
import { pick } from 'lodash';
import { firstValueFrom, map, Subject } from 'rxjs';

import { ExceptionHandler } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiService } from './api.service';
import { Display, DisplaySummary } from './display';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiSearch } from './api-utils.service';
import { ApiListResponse, ApiResponse } from '../api';

@Injectable({
  providedIn: 'root'
})
export class DisplayApiService extends ApiService<Display> {
  static readonly DISPLAY_WRITABLE_FIELDS = [
    'name', 'status', 'useCompanyAddress', 'addressDescription', 'street',
    'unit', 'city', 'province', 'country', 'postalCode', 'timeZoneOffset',
    'restartEnabled', 'restartTime', 'autostartOnBoot', 'width',
    'height', 'orientation', 'monitoringEnabled', 'monitoringEmails', 'monitoringSchedule',
    'activationKey', 'overrideTakeoverAllowed', 'displayControl', 'favoredBy',
    'screenSharingEnabled', 'settings'
  ];

  displaysLoadedSubject: Subject<Array<any>> = new Subject();

  constructor(
    protected httpClient: HttpClient,
    protected companyStateService: CompanyStateService,
    private exceptionHandler: ExceptionHandler
  ) {
    super(httpClient, companyStateService);
  }

  override getResourceUrl(): string {
    return '/v2/displays';
  }

  override toServerModel(entity: Display, isNew: boolean): any {
    const fields = pick(entity, DisplayApiService.DISPLAY_WRITABLE_FIELDS);

    if (isNew) {
      fields.assignLicense = entity.playerProAuthorized;
    }

    return fields;
  }

  list (search: ApiSearch, cursor?: string): Promise<ApiListResponse<Display>> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('search', search.query || '*')
      .set('filter', search.filter || '')
      .set('includeSubcompanies', search.includeSubcompanies || false)
      .set('cursor', cursor)
      .set('count', search.count)
      .set('sort', search.sortBy + (search.reverse ? ' desc' : ' asc'));

    return firstValueFrom(super.listAsObservable(params)
      .pipe(
        map((resp) => {
          try {
            if (resp.items) {
              for (const item of resp.items) {
                item.lastActivityDate = item.onlineStatus === 'online' ? new Date() : (item
                  .lastActivityDate ? new Date(item.lastActivityDate) : '');
              }
            }

            this.displaysLoadedSubject.next(resp.items);
          } catch(e) {
            this.exceptionHandler(e, 'Failed process displays.', true);
          }

          return resp;
        })
      ));
  }

  get (displayId: string): Promise<ApiResponse<Display>> {
    return firstValueFrom(super.getAsObservable(displayId))
      .then((resp) => {
        const item = resp.item;

        if (item) {
          item.lastActivityDate = item.onlineStatus === 'online' ? new Date() : (item
            .lastActivityDate ?
            new Date(item.lastActivityDate) : '');

          if (item.overrideTakeoverAllowed === undefined) {
            item.overrideTakeoverAllowed = true;
          }

          item.settings = item.settings || {};

          if (!!item.settings.screenSharingMode && item.screenSharingEnabled) {
            item.screenSharingStandard = item.settings.screenSharingMode.includes('standard');
            item.screenSharingModerated = item.settings.screenSharingMode.includes('moderated');
          }

          item.autostartOnBoot = item.autostartOnBoot === undefined ? true : item.autostartOnBoot;

          this.displaysLoadedSubject.next([item]);
        }

        return resp;
      });
  }

  add (display: any): Promise<ApiResponse<Display>> {
    return firstValueFrom(super.addAsObservable(display));
  }

  update (displayId: string, display: any): Promise<ApiResponse<Display>> {
    return firstValueFrom(super.updateAsObservable(displayId, display));
  }

  delete (displayId: string): Promise<ApiResponse<Display>> {
    return firstValueFrom(super.deleteAsObservable(displayId));
  }

  restart (displayId: string): Promise<ApiResponse<Display>> {
    return firstValueFrom(super.requestAsObservable(displayId, 'PATCH', 'restart'));
  }

  reboot (displayId: string): Promise<ApiResponse<Display>> {
    return firstValueFrom(super.requestAsObservable(displayId, 'PATCH', 'reboot'));
  }

  requestScreenshot (displayId, clientId) {
    const params = new HttpParams()
      .set('clientId', clientId);

    return firstValueFrom(super.requestAsObservable(displayId, 'PATCH', 'request-screenshot', params));
  }

  hasFreeDisplays (companyId: string | null, displayIds: string[]): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('companyId', companyId);
    const body = {
      'displayIds': displayIds
    };

    return firstValueFrom(super.requestAsObservable(null, 'POST', 'has-free-displays', params, body));
  }

  summary (companyId?: string | null): Promise<DisplaySummary> {
    companyId = companyId || this.companyStateService.getSelectedCompanyId();

    const params = new HttpParams()
      .set('companyId', companyId)
      .set('includeSubcompanies', false);
    
    return firstValueFrom(super.requestAsObservable(null, 'GET', 'summary', params));
  }

  export (): Promise<ApiResponse<string>> {
    const companyId = this.companyStateService.getSelectedCompanyId();
    const params = new HttpParams()
      .set('companyId', companyId);
    
    return firstValueFrom(super.requestAsObservable(null, 'GET', 'export', params));
  }

  move (displayId: string, companyId: string): Promise<ApiResponse<Display>> {
    const params = new HttpParams()
      .set('companyId', companyId);

    return firstValueFrom(super.requestAsObservable(displayId, 'PATCH', 'move', params));
  }
}
