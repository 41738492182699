import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ApiListResponse, ApiResponse } from '../api';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { getError } from './api-utils.service';

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService<T> {

  protected APIUrl = environment.SPRING_ENDPOINT_URL + this.getResourceUrl();

  constructor(
    protected httpClient: HttpClient,
    protected companyStateService: CompanyStateService
  ) {
  }

  abstract getResourceUrl(): string;

  toServerModel(entity: T, _isNew: boolean): any {
    return entity;
  }

  fromServerModel(json: any): T {
    return json;
  }

  listAsObservable(params?: HttpParams): Observable<ApiListResponse<T>> {
    return this.httpClient.get<ApiListResponse<T>>(`${this.APIUrl}`, {
        params
      })
      .pipe(
        map((response) => {
          response.items.forEach((item) => this.fromServerModel(item));

          return response;
        }),
        catchError(this.handleError)
      );
  }

  getAsObservable(id: string): Observable<ApiResponse<T>> {
    return this.httpClient.get<ApiResponse<T>>(`${this.APIUrl}/${id}`)
      .pipe(
        map((response) => {
          this.fromServerModel(response.item);

          return response;
        }),
        catchError(this.handleError)
      );
  }

  addAsObservable(resource: T): Observable<ApiResponse<T>> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    return this.httpClient.post<ApiResponse<T>>(`${this.APIUrl}`, this.toServerModel(resource, true), {
      params
    })
      .pipe(
        map((response) => {
          this.fromServerModel(response.item);

          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteAsObservable(id: string): Observable<any> {
    return this.httpClient.delete(`${this.APIUrl}/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateAsObservable(id: string, resource: T): Observable<ApiResponse<T>> {
    return this.httpClient.patch<ApiResponse<T>>(`${this.APIUrl}/${id}`, this.toServerModel(resource, false))
      .pipe(
        map((response) => {
          this.fromServerModel(response.item);

          return response;
        }),
        catchError(this.handleError)
      );
  }

  requestAsObservable(id: string, method: string, path: string, params?: HttpParams, body?: any): Observable<any> {
    return this.httpClient.request<any>(method, `${this.APIUrl}${id ? '/' + id : ''}${path ? '/' + path : ''}`, {
        params,
        body
      })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(response: HttpErrorResponse) {
    console.error(`Core API call failed.`, response);

    const error = getError(response);

    // Handle the HTTP error here
    return throwError(() => error);
  }
}
